import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/HeaderFooter/Layout"
import styled from "styled-components"
import { FaLinkedinIn } from "react-icons/fa"
import ContentHeader from "../components/About/ContentHeader"
import ContentSection1 from "../components/About/ContentSection1"
import ContentSection3 from "../components/WhatWeDo/ContentSection3"
import { Helmet } from "react-helmet"

const ProfilesContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 18%;
  margin: 2% 0;
`
const ProfileTitle = styled.div`
  margin: 22px 0;
  padding-left: 40px;

  @media (max-width: 1023px) {
    padding: 0;
    margin-top: 30px;
    align-self: center;

    h1 {
      font-size: 1.6em;
    }
  }
`
const ProfilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const ProfileWrapper = styled.div`
  margin-bottom: 30px;

  @media (min-width: 1681px) {
    flex: 1 0 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ImageDiv = styled.div`
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 10px;
  transition: all 300ms;
  border: 2px solid #faa62d;

  :hover {
    box-shadow: 4px 4px 0px 0px #faa62d, 8px 8px 0px 0px #fdc068bf,
      12px 12px 0px 0px #fdcd8a9e;
    transform: scale(1.02);
  }
`

const ProfileImage = styled.img`
  width: 250px;
`

const NameDiv = styled.div`
  padding-top: 10px;
  h3 {
    font-size: 1.3em;
    font-weight: 400;
  }
`
const RoleDiv = styled.div`
  h4 {
    font-size: 1em;
    font-weight: 300;
  }
`
const LinkedinDiv = styled.div`
  padding: 10px 0;
  svg {
    fill: #0072b1;
    transition: 0.3s;
  }
  svg:hover {
    fill: #faa62d;
  }
`

export default function About({ data }) {
  const profiles = data.allSanityProfile.nodes

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ripplz - About</title>
        <meta
          name="description"
          content="Created by experienced professionals from Software Development, Marketing, Recruitment and Branding, Ripplz delivers both simple and complex software development."
        />
      </Helmet>
      <ContentHeader />
      <ContentSection1 />

      <ProfilesContentContainer>
        <ProfileTitle>
          <h1>Meet the team</h1>
        </ProfileTitle>
        <ProfilesContainer>
          {profiles.map(profile => (
            <ProfileWrapper>
              <ImageDiv>
                <ProfileImage
                  src={profile.image.asset.url}
                  key={profile.image.asset._id}
                />
              </ImageDiv>
              <NameDiv>
                <h3>{profile.name}</h3>
              </NameDiv>
              <RoleDiv>
                <h4>{profile.role}</h4>
              </RoleDiv>
              <LinkedinDiv>
                <a
                  href={profile.linkedin}
                  key={profile.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn size={20} />
                </a>
              </LinkedinDiv>
            </ProfileWrapper>
          ))}
        </ProfilesContainer>
      </ProfilesContentContainer>
      <ContentSection3 />
    </Layout>
  )
}

export const query = graphql`
  query ProfilesQuery {
    allSanityProfile(sort: { fields: name, order: DESC }) {
      nodes {
        linkedin
        name
        role
        image {
          asset {
            url
            originalFilename
            _id
          }
        }
      }
    }
  }
`
