import React from "react"
import styled from "styled-components"
import ImageRight from "../../images/team-working.jpeg"

const ContentContainer = styled.div`
  display: flex;
  padding: 0 12%;
  margin: 62px 0;
  justify-content: center;

  @media (max-width: 1023px) {
    margin: 42px 0 22px;
    padding: 0;
  }

  .ContentWrapper {
    width: 1280px;
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
`

const ContentWrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
      width: 300px;
    }

    p {
      padding: 0 20px;

      @media (max-width: 1023px) {
        width: 80%;
      }
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`
const ContentWrapperRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  .img-wrap img {
    height: 500px;
    width: 450px;
    object-fit: cover;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.24);
    border: 3px solid #faa62d;

    @media (max-width: 1224px) {
      height: 450px;
      width: 400px;
      object-fit: cover;
      margin-top: 22px;
    }

    @media (max-width: 768px) {
      height: 250px;
      width: auto;
      object-fit: cover;
    }
  }
`

export default function ContentSection1() {
  return (
    <ContentContainer>
      <div className="ContentWrapper">
        <ContentWrapperLeft>
          <div className="mission-statement">
            <h1>Our Mission</h1>
            <p>
              "To provide a platform for Gen Z software developers to perfect
              and showcase their skills, whilst enabling organisations to grow
              and develop products at scale and minimal cost."
            </p>
          </div>
          <div classNam="about-us">
            <h1>About us</h1>
            <p>
              Our team combines over 40 years experience across the technology,
              marketing and recruitment industries. This positions us at the
              forefront of the current skills crisis, delivering both simple and
              complex software development projects on time and within budget.
            </p>
          </div>
        </ContentWrapperLeft>
        <ContentWrapperRight>
          <div className="img-wrap">
            <img src={ImageRight} alt="team working" />
          </div>
        </ContentWrapperRight>
      </div>
    </ContentContainer>
  )
}
